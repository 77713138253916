program LsDev;

uses
  Vcl.Forms,
  WEBLib.Forms,
  Unit9 in 'Unit9.pas'{*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TForm9, Form9);
  Application.Run;
end.
