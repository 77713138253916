unit Unit9;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls;

type
  TForm9 = class(TForm)
    WebLabel1: TLabel;
  private
    { Déclarations privées }
  public
    { Déclarations publiques }
  protected procedure LoadDFMValues; override; end;

var
  Form9: TForm9;

implementation

{$R *.dfm}

procedure TForm9.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  try
    Name := 'Form9';
    Width := 640;
    Height := 480;
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 0;
    WebLabel1.Top := 0;
    WebLabel1.Width := 640;
    WebLabel1.Height := 37;
    WebLabel1.Align := alTop;
    WebLabel1.Alignment := taCenter;
    WebLabel1.Caption := 'LsDev Info';
    WebLabel1.Color := 33023;
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -27;
    WebLabel1.Font.Name := 'Segoe UI';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.Transparent := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
  finally
    WebLabel1.AfterLoadDFMValues;
  end;
end;

end.